import React from "react";
import { useTheme } from "styled-components";

import Header from "../../molecules/Header";
import MainTemplate from "../../templates/MainTemplate";
import Consts from "../../../config/Consts";
import BreadcrumbBlock from "../../molecules/BreadcrumbBlock";

const InfoToc = ({
  post_client,
  helmet
}) => {
  const theme = useTheme();

  return (
    <MainTemplate
      helmet={helmet}
      header={
        <Header post_client={post_client} />
      }
      main={
        <div className="l-container">
          <h1 className={`p-${theme.page}__headings`}>THE NIKKEI MAGAZINE CLUB会員規約</h1>

          <ul className={`p-${theme.page}__list`}>
            <li className={`p-${theme.page}__item`}>
              <h2 className={`p-${theme.page}__subheadings`}>第１条（目的）</h2>
              <p className={`p-${theme.page}__text`}>「THE NIKKEI MAGAZINE CLUB」（以下、「本組織」といいます。）は、株式会社日本経済新聞社（以下、「当社」といいます。）が提供する「THE NIKKEI MAGAZINE」（以下、「本メディア」といいます。）の読者を対象として、各種メールサービスの提供、イベント実施のほか、アンケートなどを通じてご意見を収集することにより、本メディアづくりに役立てることを目的とした会員組織です。</p>
            </li>
            <li className={`p-${theme.page}__item`}>
              <h2 className={`p-${theme.page}__subheadings`}>第２条（適用）</h2>
              <p className={`p-${theme.page}__text-number`}>１．THE NIKKEI MAGAZINE CLUB会員規約（以下、「本規約」といいます。）は、本組織への登録を申し込み、当社が登録を認めた個人（以下、「会員」といいます。）との間の権利義務関係を定めることを目的とし、当社と会員との間の本組織に関わる一切の関係に適用されます。なお、本規約は、当社が運営するサーバー上に置かれた本メディアの専用サイト（以下、「本サイト」といいます。）に掲載されます。</p>
              <p className={`p-${theme.page}__text-number`}>２．本組織への登録するためには本規約の内容に同意することが必要となります。登録申込前に必ず本規約をお読みの上、ご同意ください。</p>
              <p className={`p-${theme.page}__text-number`}>３．本組織に会員として登録するためには、日経ＩＤの取得が必要となります。本規約に定めがない事項については、別段の定めがない限り、またはその性質に反しない限り、日経ＩＤ利用規約（以下、「基本規約」といいます。）の各条項が直接または適切な読み替えを行ったうえで適用されるものとし、本規約と基本規約の定めが異なる場合、本規約が優先して適用されます。</p>
            </li>
            <li className={`p-${theme.page}__item`}>
              <h2 className={`p-${theme.page}__subheadings`}>第３条（会員登録）</h2>
              <p className={`p-${theme.page}__text-number`}>１．当社は以下の場合に会員登録を承諾しないことがあります。承諾しなかった場合の判断の理由については一切申込者に開示しません。また、申込者は当該判断の結果に対して異議を述べることはできません。</p>
              <p className={`p-${theme.page}__text-indent`}>（１）申込者が実在しない場合</p>
              <p className={`p-${theme.page}__text-indent`}>（２）申込者が届け出ている電話、電子メール、住所等の連絡先に連絡が取れない場合</p>
              <p className={`p-${theme.page}__text-indent`}>（３）申込者が届け出ている情報に虚偽またはこれに類する不正確な内容の記載が含まれていると判明した場合</p>
              <p className={`p-${theme.page}__text-indent`}>（４）申込者が規約違反等により当社またはそのグループ会社が運営するサービス等の利用を停止されたことがある場合等、当社が会員登録を承諾すべきではないと判断した場合</p>
              <p className={`p-${theme.page}__text-indent`}>（５）当社の日常的な業務の遂行上または技術上、支障がある場合</p>
              <p className={`p-${theme.page}__text-indent`}>（６）その他当社が不適当と認めた場合</p>
              <p className={`p-${theme.page}__text-number`}>２．当社が、申込者が登録した電子メールアドレス宛に会員登録完了の確認メールを送信した時点で、当該申込者の本組織への会員登録が完了するものとします。</p>
            </li>
            <li className={`p-${theme.page}__item`}>
              <h2 className={`p-${theme.page}__subheadings`}>第４条（登録情報の変更）</h2>
              <p className={`p-${theme.page}__text-number`}>１．会員は、登録情報に変更が生じた場合には、速やかに当社所定の方法で変更の届出をするものとします。ただし、手続きの関係上、変更の手続きをされてから、当該変更が有効になるまで日数を要することがあります。</p>
              <p className={`p-${theme.page}__text-number`}>２．会員が前項の届出をするまでの間に、または前項の届出を怠るなど登録情報の変更が適切に行われないために不利益を被ったとしても、当社は一切その責任を負いません。</p>
            </li>
            <li className={`p-${theme.page}__item`}>
              <h2 className={`p-${theme.page}__subheadings`}>第５条（会員サービス）</h2>
              <p className={`p-${theme.page}__text-number`}>１．会員は以下の各号のサービス（以下、「会員サービス」といいます。）を受けることができます。なお、会員は、以下の各号のうち、「必須」と記載がされている各種メールサービスについては、本組織が当該サービスを主たる会員サービスとして位置付けている性質上、受信しないことを選択することができません。やむを得ず受信しないことを希望される場合には、本規約第１０条（退会）の規定に従い退会処理をしていただくことで対応させていただきます。あらかじめご了承ください。</p>
              <p className={`p-${theme.page}__text-indent`}>（１）「THE NIKKEI MAGAZINE CLUBメールマガジン」の受信（必須）</p>
              <p className={`p-${theme.page}__text-indent`}>（２）当社からの各種案内メールの受信（必須）</p>
              <p className={`p-${theme.page}__text-indent`}>（３）当社が本組織に関連して主催または共催するイベントおよびアンケート等への参加</p>
              <p className={`p-${theme.page}__text-indent`}>（４）その他当社が定めるサービス</p>
              <p className={`p-${theme.page}__text-number`}>２．前項(1)および(2)の電子メールには、当社、当社のグループ各社および前項(3)に定めるイベントやアンケート等の協賛・共催企業（以下、「協賛企業等」といいます。）の商業広告が含まれる場合があります。</p>
              <p className={`p-${theme.page}__text-number`}>３．当社は、会員の了承を得ることなく会員サービスの内容を随時追加、変更、中止または終了できるものとします。</p>
            </li>
            <li className={`p-${theme.page}__item`}>
              <h2 className={`p-${theme.page}__subheadings`}>第６条（会費）</h2>
              <p className={`p-${theme.page}__text`}>本組織の会費は無料とします。ただし、当社は本組織に関連して有料のイベント等を実施することがあります。</p>
            </li>
            <li className={`p-${theme.page}__item`}>
              <h2 className={`p-${theme.page}__subheadings`}>第７条（個人情報等の取り扱い）</h2>
              <p className={`p-${theme.page}__text-number`}>１．日経ＩＤの取得時に当社が取得した会員に関する個人情報（以下、「日経ＩＤ個人情報」といいます。）および本組織への登録時に新たに当社が取得した会員に関する情報（以下、「本組織関連個人情報」といい、「日経ＩＤ個人情報」とあわせて「会員の個人情報」といいます。）は、当社が、「<a href={Consts.LINK.ABOUT_PRIVACY_URL} target="_blank" rel="noopener">日経ＩＤプライバシーポリシー</a>」にしたがって取り扱います。</p>
              <p className={`p-${theme.page}__text-number`}>２．当社は、会員の個人情報を本組織の運営および会員サービスの提供の目的で利用します。</p>
              <p className={`p-${theme.page}__text-number`}>３．前項のほか、当社は、会員の個人情報を、協賛企業等に提供する場合があります。この場合、当社および協賛企業等は、当該個人情報の利用目的を明示し、会員の事前の同意を得るものとします。</p>
              <p className={`p-${theme.page}__text-number`}>４．会員が本組織を退会した場合、当社が会員の本組織の会員資格を停止した場合、または本組織が終了した場合には、当社は本組織関連個人情報を消去します。ただし、当社が、履歴検証を行う場合その他当社が必要と認めた場合には、当該情報のバックアップデータを１３カ月間保有し、その後消去するものとします。</p>
              <p className={`p-${theme.page}__text-number`}>５．会員が本組織を退会した場合であっても、日経ＩＤから退会しない限り、日経は当該会員に関する日経ＩＤ個人情報を消去せずに保有し、「日経ＩＤプライバシーポリシー」にしたがって取り扱います。</p>
            </li>
            <li className={`p-${theme.page}__item`}>
              <h2 className={`p-${theme.page}__subheadings`}>第８条（アンケート）</h2>
              <p className={`p-${theme.page}__text-number`}>１．当社は、会員に対して、当社または協賛企業等のために、電子メールまたはイベント等においてアンケートを実施することがあります。</p>
              <p className={`p-${theme.page}__text-number`}>２．前項の場合、会員がアンケートにおいて回答した内容の取り扱いは、基本規約第１３条（利用者発信コンテンツの取り扱い）に関する定めに準ずるものとします。</p>
            </li>
            <li className={`p-${theme.page}__item`}>
              <h2 className={`p-${theme.page}__subheadings`}>第９条（メールサービス）</h2>
              <p className={`p-${theme.page}__text-number`}>１．本組織における会員へのメール送信に際し、メールの未送信、遅延、文字化け、同一メールの複数回送信が発生しても、当社はその責任を負いません。</p>
              <p className={`p-${theme.page}__text-number`}>２．会員が受信したメールを削除・紛失した場合も、当社は当該メールの再送信は行いません。</p>
              <p className={`p-${theme.page}__text-number`}>３．会員が登録しているメールアドレスを変更した場合、当該変更が有効となり、変更後のメールアドレスに当社からのメールが届くまでには日数を要することを会員はあらかじめ了承し、当社に対して再送信等の要求を行わないものとします。</p>
            </li>
            <li className={`p-${theme.page}__item`}>
              <h2 className={`p-${theme.page}__subheadings`}>第１０条（退会）</h2>
              <p className={`p-${theme.page}__text`}>会員は、本組織から退会する場合は、当社所定の方法により会員自身で退会の手続きをするものとし、当社が確認したことをもって会員が退会したものとします。</p>
            </li>
            <li className={`p-${theme.page}__item`}>
              <h2 className={`p-${theme.page}__subheadings`}>第１１条（会員資格の停止）</h2>
              <p className={`p-${theme.page}__text`}>当社は、基本規約第２２条（利用停止）第１項に定める場合のほか、会員が次の各号のいずれかに該当すると判断した場合、会員への事前の通知もしくは催告を要することなく会員資格を停止することができます。</p>
              <p className={`p-${theme.page}__text`}>（１）本規約に違反した場合</p>
              <p className={`p-${theme.page}__text`}>（２）会員が届け出ている情報に虚偽またはこれに類する不正確な内容の記載が含まれていると判明した場合</p>
              <p className={`p-${theme.page}__text`}>（３）会員が届け出ているメールアドレスに当社が送信した電子メールが不達となった場合</p>
              <p className={`p-${theme.page}__text`}>（４）日経ＩＤから退会した場合</p>
              <p className={`p-${theme.page}__text`}>（５）日経により、日経ＩＤの利用契約が解除された場合</p>
              <p className={`p-${theme.page}__text`}>（６）反社会的勢力に関与する行為および反社会的勢力を利用する行為の存在があった場合</p>
              <p className={`p-${theme.page}__text`}>（７）その他合理的な事由により会員として不適切と当社が判断した場合</p>
            </li>
            <li className={`p-${theme.page}__item`}>
              <h2 className={`p-${theme.page}__subheadings`}>第１２条（本組織の中断等）</h2>
              <p className={`p-${theme.page}__text-number`}>１．当社は、以下の場合には、会員に事前に通知することなく、本組織の全部または一部の運営を中断または停止することができるものとします。</p>
              <p className={`p-${theme.page}__text-indent`}>（１）本組織にかかる設備の保守または点検によりやむを得ない場合</p>
              <p className={`p-${theme.page}__text-indent`}>（２）本組織にかかる設備にやむを得ない障害等が発生した場合</p>
              <p className={`p-${theme.page}__text-indent`}>（３）電気通信事業者が電気通信サービスの提供を中止することにより本組織の運営が困難となった場合</p>
              <p className={`p-${theme.page}__text-indent`}>（４）感染症の蔓延、戦争、暴動、労働争議、天災地変（地震、噴火、洪水、津波等）、火災、停電その他の非常事態により、本組織の運営ができなくなった場合</p>
              <p className={`p-${theme.page}__text-indent`}>（５）その他、当社が本組織の運営上、中断または終了が必要と判断した場合</p>
              <p className={`p-${theme.page}__text-number`}>２．当社は、本組織運営の中断、事故・障害等によって、直接または間接的に生じた会員または第三者の損害について、自らに故意または重大な過失がある場合を除いて一切責任を負いません。</p>
              <p className={`p-${theme.page}__text-number`}>３．その他、本組織に関する当社の免責については、基本規約第３０条（免責および損害賠償）の定めが適用されます。</p>
            </li>
            <li className={`p-${theme.page}__item`}>
              <h2 className={`p-${theme.page}__subheadings`}>第１３条（本組織の終了）</h2>
              <p className={`p-${theme.page}__text`}>当社は、会員に事前の通知をすることなく、本組織を終了することができるものとします。</p>
            </li>
            <li className={`p-${theme.page}__item`}>
              <h2 className={`p-${theme.page}__subheadings`}>第１４条（本規約の変更）</h2>
              <p className={`p-${theme.page}__text-number`}>１．当社は、利用者の承諾を得ることなく本規約を改定できるものとし、利用者は、当社が本規約を随時改定することおよび会員サービスの利用条件等が改定後の本規約によることを了承するものとします。</p>
              <p className={`p-${theme.page}__text-number`}>２．本規約の改定後の内容は、当社が別途定める場合を除いて、改定後の本規約が本サイト上に掲示された時点から効力を生じるものとします。</p>
            </li>
            <li className={`p-${theme.page}__item`}>
              <p className={`p-${theme.page}__text u-text-right`}>以上<br />（改定日：2023年4月17日）</p>
            </li>
          </ul>

          <BreadcrumbBlock verification={{label: "利用規約", path: Consts.COMMON.BASE_URL + "/info/toc.html"}} />
        </div>
      }
    />
  )
};

export default InfoToc;