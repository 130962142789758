import React from "react";
import { useTheme } from "styled-components";

import ArticleList2ColumnBlock from "../ArticleList2ColumnBlock";
import SideBarBlock from "../SideBarBlock";

const ArticleList2ColumnWithSideBarBlock = ({
  topic_id, label, verification
}) => {
  const theme = useTheme();

  return (
    <section className={`p-${theme.page}__article l-article has-breadcrumb`}
      data-testid="block-name-article-list">
      <div className="l-container">
        <h1 className={`p-${theme.page}__headings`}>{label}</h1>
        <h2></h2>
        <div className={`p-${theme.page}__article-wrapper l-article__wrapper`}>
          <ArticleList2ColumnBlock topic_id={topic_id} verification={verification} />
          <SideBarBlock
            topic_id={topic_id}
            breadcrumb={true}
            verification={verification}
            ad1={true}
            tag={true}
            ad2={true}
            ranking={true}
            prarticle_first_index={1}
            ad3_pc={true}
            banner={true}
          />
        </div>
      </div>
    </section>
  )
};

export default ArticleList2ColumnWithSideBarBlock;