import React from "react";
import { useTheme } from "styled-components";
import SimpleA from "../../atoms/SimpleA";
import Consts from "../../../config/Consts";

const BreadcrumbBlock = ({
  verification, article_data, device
}) => {
  const theme = useTheme();

  const breadcrumb_list = [{name: "TOP", link: "/"}];
  if(article_data) {
    if(theme.page === "news") {
      if(article_data.sub_title_1) {
        if(article_data.operation_tags.indexOf('D105') === -1 && 
           article_data.operation_tags.indexOf('D106') === -1 &&
           article_data.operation_tags.indexOf('D110') === -1 ) {
          breadcrumb_list.push({name: article_data.name, link: null})
          breadcrumb_list.push({name: article_data.sub_title_1, link: article_data.article_encoded_id ? `${Consts.COMMON.BASE_URL}/news/${article_data.article_encoded_id}` : null})
        } else {
          breadcrumb_list.push({name: 'あなたへのお知らせ', link: `${Consts.COMMON.BASE_URL}/notification`})
          breadcrumb_list.push({name: article_data.sub_title_1, link: article_data.article_encoded_id ? `${Consts.COMMON.BASE_URL}/notification/${article_data.article_encoded_id}` : null})
        }
      }
    } else if(theme.page === "notice") {
      breadcrumb_list.push({name: article_data.name, link: `${Consts.COMMON.BASE_URL}/notification`})
    } else {
      if(article_data.name) {
        breadcrumb_list.push({name: article_data.name, link: article_data.category_path ? `${Consts.COMMON.BASE_URL}${article_data.category_path}` : null})
      }
      if(article_data.sub_name) {
        breadcrumb_list.push({name: article_data.sub_name, link: article_data.sub_category_path ? `${Consts.COMMON.BASE_URL}${article_data.sub_category_path}` : null})
      }
      if(article_data.sub_title_1) {
        breadcrumb_list.push({name: article_data.sub_title_1, link: article_data.article_encoded_id ? `${Consts.COMMON.BASE_URL}/article/${article_data.article_encoded_id}` : null})
      }
    }
  } else if(verification) {
    if(verification.label) {
      breadcrumb_list.push({name: verification.label, link: verification.path ? verification.path.startsWith(Consts.COMMON.BASE_URL) ? verification.path : `${Consts.COMMON.BASE_URL}/${verification.path}` : null})
    }
    if(verification.sub_label) {
      breadcrumb_list.push({name: verification.sub_label, link: verification.sub_path ? `${Consts.COMMON.BASE_URL}/${verification.path}/${verification.sub_path}` : null})
    }
  }

  const list = breadcrumb_list.map((item, index) => {
    return (
      <React.Fragment key={`bcb-fragment-${index}`}>
        <li className="m-breadcrumb-list__item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
          {item.link ?
            <SimpleA href={item.link} itemProp="item" data-atlas-trackable="breadcrumb"><span className="m-breadcrumb-list__item-name" itemProp="name">{item.name}</span></SimpleA>
          :
            <span className="m-breadcrumb-list__item-name" itemProp="name">{item.name}</span>
          }
          <meta itemProp="position" content={index + 1} />
        </li>
        {index === breadcrumb_list.length - 1 ? null : <span className="m-breadcrumb-list__separator">&gt;</span>}
      </React.Fragment>
    )
  })

  return (
    <section className={`p-${theme.page}__breadcrumb${device === "pc" ? " is-pc" : device === "sp" ? " is-sp" : ""}`} data-testid="block-name-breadcrumb">
      <nav className="m-breadcrumb" aria-label="Breadcrumb">
        <ol className="m-breadcrumb-list" itemScope itemType="http://schema.org/BreadcrumbList">
          {list}
        </ol>
      </nav>
    </section>
  )
};

export default BreadcrumbBlock;