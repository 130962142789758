import React from "react";
import { useTheme } from "styled-components";

import AccessLog from "../../molecules/AccessLog";
import ArticleBody from "../../molecules/ArticleBody";
import ArticleShare from "../../molecules/ArticleShare";
import ArticleTagList from "../../molecules/ArticleTagList";
import RelatedBlock from "../RelatedBlock";
import AdInread from "../../ads/AdInread";
import adid from "../../../config/adid";
import AdSidebar from "../../ads/AdSidebar";
import BreadcrumbBlock from "../../molecules/BreadcrumbBlock";

const ArticleBlock = ({
  article_detail, additional = '', post_client
}) => {
  const theme = useTheme();
  if(article_detail) {
    const related_list = article_detail.related_all_list;
    return (
      <div className={`p-${theme.page}__article-main l-article__main`}>
        <ArticleBody article_detail={article_detail} />
        <AdSidebar adtype="RECTANGLE1" pc={false} sp={true} />
        <RelatedBlock related_list={related_list} with_empty_padding={true} />
        {additional && additional}
        <AccessLog post_client={post_client} article_detail={article_detail} />
        <ArticleShare post_client={post_client} article_detail={article_detail} />
        <div className="p-contents__ad m-ad-contents is-pc">
          <AdInread adid={adid.PC[theme.adpagetype].INREAD} device="pc" />
        </div>
        <AdSidebar adtype="SCROLLER" pc={false} sp={true} />
        <AdInread adid={adid.SP[theme.adpagetype].INREAD} device="sp" ext={true}/>
        <ArticleTagList article_data={article_detail.article_data} />
        <BreadcrumbBlock article_data={article_detail.article_data} />
      </div>
    )
  } else {
    return (
      <div className={`p-${theme.page}__article-main l-article__main`}>
        <div className="p-contents__ad m-ad-contents is-pc">
          <AdInread adid={adid.PC[theme.adpagetype].INREAD} device="pc" />
        </div>
        <AdSidebar adtype="SCROLLER" pc={false} sp={true} />
        <AdInread adid={adid.SP[theme.adpagetype].INREAD} device="sp" />
      </div>
    )
  }
};

export default ArticleBlock;