import React from "react";
import { ThemeProvider } from "styled-components";
import Header from "../../molecules/Header";
import Nav from "../../molecules/Nav";
import NoticeBlock from "../../organisms/NoticeBlock";
import MainTemplate from "../../templates/MainTemplate";
import addslashes from "../../../utils/AddSlashes";
import BreadcrumbBlock from "../../molecules/BreadcrumbBlock";
import Consts from "../../../config/Consts";

const NoticePage = ({post_client}) => {

  const label = 'あなたへのお知らせ'
  const title = label + '｜THE NIKKEI MAGAZINE｜日本経済新聞社'
  const description = ''
  const analyticsVars = 'var analyticsVars = {topic_id: "", topic_name: "notification", sub_topic_id: "", sub_topic_name: "", title:"' + addslashes(title) + '"};';
  const helmet = [
    <title key='h-title'>{title}</title>,
    <meta key='h-meta-1' name="robots" content="noindex" />,
    <meta key='h-meta-2' name="description" content={description} />,
    <meta key='h-meta-3' property="og:title" content={title} />,
    <meta key='h-meta-4' property="og:description" content={description} />,
    <script key='h-script-1' type="text/javascript">{analyticsVars}</script>
  ]

  const mode = 'default';
  const page = 'notice';
  const adpagetype = 'ARTICLE';

  return (
    <ThemeProvider theme={{ mode, page, adpagetype }}>
      <MainTemplate
        helmet={helmet}
        header={
          <Header isTop={true} post_client={post_client} />
        }
        main={
          <>
            <Nav />
            <div className="l-container">
              <h1 className={`p-${page}__headings`}>{label}</h1>
              <h2></h2>
            </div>

            <NoticeBlock post_client={post_client} />

            <div className="l-container">
              <BreadcrumbBlock verification={{label: label, path: Consts.COMMON.BASE_URL + "/notification"}} />
            </div>
          </>
        }
      />

    </ThemeProvider>
  )
};

export default NoticePage;