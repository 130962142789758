import React, { useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import { ThemeProvider } from "styled-components";
import { useParams } from 'react-router-dom';

import Header from "../../molecules/Header";
import MainTemplate from "../../templates/MainTemplate";
import Consts from "../../../config/Consts";
import htmlEntities from '../../../utils/HtmlEntities';
import { lm_substr } from "../../../utils/StrUtil";
import { QueryStringContext } from "../../../contexts/QueryStringContext";
import Nav from "../../molecules/Nav";
import News from "../../organisms/News";
import addslashes from "../../../utils/AddSlashes";

const GET_ARTICLE_DETAIL = gql`
  query (
    $token: ID,
    $article_id: ID,
    $date_staging: String
  ){
    article_detail(
      token: $token,
      article_id: $article_id,
      date_staging: $date_staging
    ) {
      article_data {
        article_id
        article_encoded_id
        name
        sub_name
        news_attribute_id
        source
        display_time
        date_public
        date_modified
        sub_title_detail
        sub_title_sec
        sub_title_1
        sub_title_2
        snippet
        snippet2_continue_flg
        media_name
        article_text_convert_tag_by_page_list
        link_type
        link_info
        writer1
        writer2
        writer
        author
        keyword
        article_next_title
        page_number
        total_page
        wappen
        exinfo
        original_url
        id_type
        topic_id
        sub_topic_id
        topics {
          topic_id
          label
        }
        image_twitter
        operation_tags
        service_category
      }
    }
  }
`

const NewsPage = ({post_client}) => {
  let { article_id } = useParams();
  const { queryString } = useContext(QueryStringContext);
  const date_staging = typeof queryString.k !== 'undefined' ? queryString.k : ''

  const { loading, error, data } = useQuery(GET_ARTICLE_DETAIL, {
    variables: {
      "token": "ContentsPage",
      "article_id": article_id,
      "date_staging": date_staging
    }
  });
  if (loading) return null;
  if (error) return null;
  if (data.article_detail.article_data === null) {
    throw new Error('NOTFOUND');
  }

  const article_data = data.article_detail.article_data;

  const title = article_data.sub_title_1 + '｜THE NIKKEI MAGAZINE'
  const description = htmlEntities(
    lm_substr(
      article_data.snippet.replace(/\r?\n/g, ''),
      Consts.LIMIT.META_ARTICLE_DESCRIPTION_LIMIT,
      Consts.LIMIT.THREE_DOTS,
      article_data.snippet2_continue_flg),
    'encode');
  let sub_topic_id = ''
  let sub_topic_name = ''
  if(article_data.sub_topic_id) {
    sub_topic_id = article_data.sub_topic_id;
    sub_topic_name = addslashes(article_data.sub_name);
  }
  let operation_tags_str = ''
  if(article_data.operation_tags.length > 0) {
    for(let tag of article_data.operation_tags) {
      operation_tags_str += ',\''+tag+'\''
    }
    operation_tags_str = operation_tags_str.substring(1)
  }
  let tag_id_str = ''
  let tag_name_str = ''
  if(article_data.topics.length > 0) {
    for(let topic of article_data.topics) {
      tag_id_str += ',\''+topic.topic_id+'\''
      tag_name_str += ',\''+addslashes(topic.label)+'\''
    }
    tag_id_str = tag_id_str.substring(1)
    tag_name_str = tag_name_str.substring(1)
  }
  let analyticsVars = 'var analyticsVars = {'
  + 'article_id:"' + article_data.article_id + '"'
  + ',topic_id:"' + article_data.topic_id + '"'
  + ',topic_name:"' + addslashes(article_data.name) + '"'
  + ',sub_topic_id:"' + sub_topic_id + '"'
  + ',sub_topic_name:"' + sub_topic_name + '"'
  + ',page:"' + article_data.page_number + '/' + article_data.total_page + '"'
  + ',title:"' + addslashes(title) + '"'
  + ',author_name:"' + addslashes(article_data.author) + '"'
  + ',author_name1:"' + addslashes(article_data.writer1) + '"'
  + ',operation_tags:[' + operation_tags_str + ']'
  + ',tag_id:[' + tag_id_str + ']'
  + ',tag_name:[' + tag_name_str + ']'
  + ',tu_id:"' + addslashes(article_data.writer2) + '"'
  + '};';
  const helmet = [
    <title key='h-title'>{title}</title>,
    <meta key='h-meta-1' name="description" content={description} />,
    <meta key='h-meta-2' property="og:title" content={title} />,
    <meta key='h-meta-3' property="og:description" content={description} />,
    <link key='h-canonical' rel="canonical" href={`${Consts.COMMON.BASE_URL}/news${article_id?'/'+article_id:''}`} />,
    <script key='h-script-1' type="text/javascript">{analyticsVars}</script>
  ]

  const mode = 'default';
  const page = 'news';
  const adpagetype = 'ARTICLE';

  return (
    <ThemeProvider theme={{ mode, page, adpagetype }}>
      <MainTemplate
        helmet={helmet}
        header={
          <Header isTop={true} post_client={post_client} />
        }
        main={
          <>
            <Nav />
            <News article_detail={data.article_detail} />
          </>
        }
      />
    </ThemeProvider>
  )
};

export default NewsPage;