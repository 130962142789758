import React from "react";
import { useTheme } from "styled-components";

import AdSidebar from "../../ads/AdSidebar";
import TagBlock from "../../molecules/TagBlock";
import HtmlBanner from "../../molecules/HtmlBanner";
import ArticleListPr from "../ArticleListPr";
import RankingBlock from "../RankingBlock";
import BreadcrumbBlock from "../../molecules/BreadcrumbBlock";

const SideBarBlock = ({
  topic_id='',
  breadcrumb=false,
  ad1=false,
  ad1_sp=true,
  ad1_sp_rectangle=true,
  tag=false,
  ad2=false,
  ad2_sp=false,
  ranking=false,
  ad3=false,
  ad3_pc=false,
  prarticle_first_index=false,
  banner=false,
  verification=null
}) => {
  const theme = useTheme();

  return (
    <div className={`p-${theme.page}__article-sidebar l-article__sidebar`}>
      {ad1 &&
        // SPはこの位置でインタスクローラー、インリードを表示
        // PCはこの位置でレクタングル1を表示
        <>
          <AdSidebar adtype="SCROLLER" pc={false} sp={ad1_sp} />
          <AdSidebar adtype="INREAD" pc={false} sp={ad1_sp} />

          <AdSidebar adtype="RECTANGLE1" pc={true} sp={false} />
        </>
      }

      {breadcrumb &&
        <BreadcrumbBlock verification={verification} device="sp" />
      }

      {ranking &&
        <RankingBlock topic_id={topic_id} />
      }

      {ad1 && ad1_sp_rectangle &&
        // SPはこの位置でレクタングル1を表示
        <AdSidebar adtype="RECTANGLE1" pc={false} sp={true} />
      }

      {ad2_sp &&
        // この位置の広告はSPのみ表示
        <AdSidebar adtype="RECTANGLE2" pc={false} sp={true} />
      }

      {tag &&
        <TagBlock space={false} />
      }

      {ad2 &&
        // PCはこの位置でレクタングル2を表示
        <AdSidebar adtype="RECTANGLE2" pc={true} sp={false} />
      }

      {ad3 &&
        // この位置の広告はPCのみ表示
        <AdSidebar adtype="RECTANGLE2" pc={true} sp={false} />
      }

      {prarticle_first_index &&
        <ArticleListPr adpage={1} first_adindex={100} />
      }

      {ad3_pc &&
        // この位置の広告はPCのみ表示
        <AdSidebar adtype="RECTANGLE3" pc={true} sp={false} />
      }

      {banner &&
        <HtmlBanner device="pc" />
      }
    </div>
  )
};

export default SideBarBlock;