import React, { useRef } from "react";
import { useTheme } from "styled-components";
import adid from "../../../config/adid";
import AdInread from "../../ads/AdInread";

import ArticleList2ColumnList from "../ArticleList2ColumnList";
import BreadcrumbBlock from "../../molecules/BreadcrumbBlock";

const ArticleList2ColumnBlock = ({
  topic_id, verification
}) => {
  const theme = useTheme();
  const adSection = useRef();

  const adpagetype = 'ARTICLE';
  const removeSection = () => {
    adSection.current.remove();
  }

  return (
    <div className={`p-${theme.page}__article-main l-article__main`}>
      <ArticleList2ColumnList topic_id={topic_id}/>
      <section className="p-index__ad-middle m-ad-middle is-pc" ref={adSection}>
      <AdInread adid={adid.PC[adpagetype].INREAD} device="pc" noad={removeSection} />
      </section>
      <BreadcrumbBlock verification={verification} device="pc" />
    </div>
  )
};

export default ArticleList2ColumnBlock;