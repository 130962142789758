import React from "react";
import { useTheme } from "styled-components";

import TagArticleList2ColumnBlock from "../TagArticleList2ColumnBlock";
import SideBarBlock from "../SideBarBlock";

const TagArticleList2ColumnWithSideBarBlock = ({
  topic_id, label, verification
}) => {
  const theme = useTheme();

  return (
    <section className={`p-${theme.page}__article l-article`}>
      <div className="l-container">
        {label.length > 0 &&
          <>
            <h1 className={`p-${theme.page}__headings`}>{label}</h1>
            <h2></h2>
            <div className={`p-${theme.page}__article-wrapper l-article__wrapper`}>
              <TagArticleList2ColumnBlock topic_id={topic_id} verification={verification} />
              <SideBarBlock
                breadcrumb={true}
                verification={verification}
                ad1={true}
                tag={true}
                ad2={true}
                ranking={true}
                prarticle_first_index={3}
                ad3_pc={true}
                banner={true}
              />
            </div>
          </>
        }
      </div>
    </section>
  )
};

export default TagArticleList2ColumnWithSideBarBlock;